* {
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
  box-sizing: border-box;
}

html {
  font-size: 10px;
  /* overflow-x: hidden; */
}

.datepicker {
  padding: 1rem;
  /* width: 12rem; */
  text-align: center;
  font-size: 1.4rem;
  color: #090e1b;
  border: 0.5px solid #e4ebf2;
  overflow: hidden;
}

.react-datepicker {
  font-size: 1.3rem !important;
}

.react-datepicker__current-month {
  font-size: 1.5rem !important;
}

.react-datepicker__header {
  padding-top: 6px !important;
}

.react-datepicker__navigation {
  top: 13px !important;
}

.react-datepicker__day-name,
.react-datepicker__day {
  margin: 0.5rem !important;
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: #1bb6c1;
  font-size: 8px;
  margin: 2rem auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5rem;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5rem;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 1.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 1.5em 0 0;
  }
}

@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 1.5rem 0 -1.3rem;
  }
  40% {
    box-shadow: 0 1.5rem 0 0;
  }
}
.st0 {
  fill: #bce4fa;
}
.st1 {
  fill: #dfdfdf;
}
.st2 {
  fill: #61cad6;
}
.st3 {
  fill: #6bd5e1;
}
.st4 {
  fill: #e49d75;
}
.st5 {
  fill: #ffc48e;
}
.st6 {
  fill: #ffd98e;
}
.st7 {
  fill: #ffe3ad;
}
.st8 {
  fill: #ff8364;
}
.st9 {
  fill: #0085fe;
}
.st10 {
  fill: none;
  stroke: #0085fe;
  stroke-linecap: round;
  stroke-linejoin: round;
}