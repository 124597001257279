:root {
    --loader-background-color: #EEEEEE;
    --loader-highlight-color: #DEDEDE;
}

.card {
    background-color: #fff;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));  
    border-radius: 8px;
    padding: 20px;
    text-align: center;
}

.skeleton-loader {
    height: 20px;
    background: linear-gradient(90deg, var(--loader-background-color) 25%, var(--loader-highlight-color) 50%, var(--loader-background-color) 75%);
    background-size: 200% 100%;
    animation: loading 2s infinite ease-in-out;
    border-radius: 8px;
    margin-bottom: 20px;
}

.avatar-skeleton {
    width: 100px;
    height: 100px;
    background: linear-gradient(90deg, var(--loader-background-color) 25%, var(--loader-highlight-color) 50%, var(--loader-background-color) 75%);
    background-size: 200% 100%;
    animation: loading 2s infinite ease-in-out;
    border-radius: 50%;
    margin-bottom: 20px;
}

.cover-image-skeleton {
    width: 300px;
    height: 200px;
    background: linear-gradient(90deg, var(--loader-background-color) 25%, var(--loader-highlight-color) 50%, var(--loader-background-color) 75%);
    background-size: 200% 100%;
    animation: loading 2s infinite ease-in-out;
    border-radius: 8px;
    margin-bottom: 20px;
}

@keyframes loading {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}
